<script>
import Horizontal from "./horizontal";
import successModal from "@/components/success.vue";
import ax from '../../helpers/restful/service';
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";

export default {
    components: {
        Horizontal,
        successModal,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid || 0,

            vs_identity: `VS${String(JSON.parse(localStorage.getItem('auth.currentUser')).uid).padStart(6, "0")}`,
            firstname: "",
            lastname: "",
            phone: "",
            line_id: "",
            otp: "",
            verify_otp: "",
            birthday: "",
            national: "",
            origin: "",
            religion: "",
            identity_number: "",
            identity_number_file: "",
            license1: "",
            license1_file: "",
            license2: "",
            license2_file: "",
            license3: "",
            license3_file: "",
            license4: "",
            license4_file: "",
            license5: "",
            license5_file: "",

            address_no: "",
            address_mooban: "",
            address_soi: "",
            address_road: "",
            address_tambon: "",
            address_aumphur: "",
            address_province: "",
            address_postcode: "",

            org_name: "",
            org_institute: "",
            org_institute_file: "",
            org_year_join: "",
            org_year_end: "",
            org_business_name: "",
            org_business_name_file: "",
            org_business_position: "",
            org_status: "",
            org_file: "",
            job_type: "",
            property: "",
            property_file: "",
            property_other: "",

            isShowConfirmModal: false,
            loaded: false,
            loaderHeight: window.innerHeight * 0.1,
            completeAllField: false,
            submittedRegisted: false,
            submittedCheckPrivilege: false,

            attachName: null,
            previewImage: null,
        };
    },
    validations: {

    },
    computed: {
        ...teacherMainLayoutComputed,
    },
    mounted() {
        document.body.classList.add('authentication-bg');
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            console.log("GetUserInfo");
            console.log("UID:", this.uid);

            const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
            console.log("resp:", resp);

            if (resp.status != 200) {
                return;
            }

            this.currentUser = resp.data;
            console.log("currentUser:", this.currentUser);

            if (this.currentUser.teacher_profile != {} && this.currentUser.teacher_profile != undefined && this.currentUser.teacher_profile != null && this.currentUser.teacher_profile.uid >= 0) {
                this.vs_identity = this.currentUser.username || '';
                this.identity_number = this.currentUser.teacher_profile.identity_number || '';
                this.identity_number_file = this.currentUser.teacher_profile.identity_number_file || '';
                this.firstname = this.currentUser.teacher_profile.firstname || '';
                this.lastname = this.currentUser.teacher_profile.lastname || '';
                this.phone = this.currentUser.teacher_profile.phone || '';
                this.line_id = this.currentUser.teacher_profile.line_id || '';
                this.birthday = this.currentUser.teacher_profile.birthday || '';
                this.national = this.currentUser.teacher_profile.national || '';
                this.religion = this.currentUser.teacher_profile.religion || '';
                this.origin = this.currentUser.teacher_profile.origin || '';
                this.license1 = this.currentUser.teacher_profile.license1 || '';
                this.license1_file = this.currentUser.teacher_profile.license1_file || '';
                this.license2 = this.currentUser.teacher_profile.license2 || '';
                this.license2_file = this.currentUser.teacher_profile.license2_file || '';
                this.license3 = this.currentUser.teacher_profile.license3 || '';
                this.license3_file = this.currentUser.teacher_profile.license3_file || '';
                this.license4 = this.currentUser.teacher_profile.license4 || '';
                this.license4_file = this.currentUser.teacher_profile.license4_file || '';
                this.license5 = this.currentUser.teacher_profile.license5 || '';
                this.license5_file = this.currentUser.teacher_profile.license5_file || '';
                this.address_no = this.currentUser.teacher_profile.address_no || '';
                this.address_mooban = this.currentUser.teacher_profile.address_mooban || '';
                this.address_soi = this.currentUser.teacher_profile.address_soi || '';
                this.address_road = this.currentUser.teacher_profile.address_road || '';
                this.address_tambon = this.currentUser.teacher_profile.address_tambon || '';
                this.address_aumphur = this.currentUser.teacher_profile.address_aumphur || '';
                this.address_province = this.currentUser.teacher_profile.address_province || '';
                this.address_postcode = this.currentUser.teacher_profile.address_postcode || '';

                this.org_name = this.currentUser.teacher_profile.org_name || '';
                this.org_institute = this.currentUser.teacher_profile.org_institute || '';
                this.org_institute_file = this.currentUser.teacher_profile.org_institute_file || '';
                this.org_year_join = this.currentUser.teacher_profile.org_year_join || '';
                this.org_year_end = this.currentUser.teacher_profile.org_year_end || '';
                this.org_business_name = this.currentUser.teacher_profile.org_business_name || '';
                this.org_business_name_file = this.currentUser.teacher_profile.org_business_name_file || '';
                this.org_business_position = this.currentUser.teacher_profile.org_business_position || '';
                this.org_status = this.currentUser.teacher_profile.org_status || '';
                this.org_file = this.currentUser.teacher_profile.org_file || '';
                this.job_type = this.currentUser.teacher_profile.job_type || '';
                this.property = this.currentUser.teacher_profile.property || '';
                this.property_file = this.currentUser.teacher_profile.property_file || '';
                this.property_other = this.currentUser.teacher_profile.property_other || '';
            }

            this.loaded = true;

            console.log("loaded:", this.loaded);

        },
        inspectForm() {
            this.isShowConfirmModal = true;
        },
        closeConfirmModal() {
            this.isShowConfirmModal = !this.isShowConfirmModal;
            return;
        },
        attachment(file) {
            console.log(file);
            const input_id = file + "_input";
            this.attachName = file;
            document.getElementById(input_id).click();
        },
        async previewFile(event) {
            console.log("event:", event);
            const [file] = event.target.files;
            this[this.attachName] = URL.createObjectURL(file);
            const filename = await this.uploadFile(file);
            console.log("filename:", filename);
            this[this.attachName] = filename;
        },
        async uploadFile(file) {
            const form = new FormData();
            form.append("file", file);
            const resp = await ax.postForm("/attachment-file", form);
            console.log("resp fileupload:", resp);
            if (resp.file_name) return resp.file_name;
            return null;
        },
        async saveData() {
            const data = {
                uid: this.uid,
                identity_number: this.identity_number,
                identity_number_file: this.identity_number_file,
                firstname: this.firstname,
                lastname: this.lastname,
                phone: this.phone,
                line_id: this.line_id,
                birthday: this.birthday,
                national: this.national,
                religion: this.religion,
                origin: this.origin,
                license1: this.license1,
                license1_file: this.license1_file,
                license2: this.license2,
                license2_file: this.license2_file,
                license3: this.license3,
                license3_file: this.license3_file,
                license4: this.license4,
                license4_file: this.license4_file,
                license5: this.license5,
                license5_file: this.license5_file,
                address_no: this.address_no,
                address_mooban: this.address_mooban,
                address_soi: this.address_soi,
                address_road: this.address_road,
                address_tambon: this.address_tambon,
                address_aumphur: this.address_aumphur,
                address_province: this.address_province,
                address_postcode: this.address_postcode,
                org_name: this.org_name,
                org_institute: this.org_institute,
                org_institute_file: this.org_institute_file,
                org_year_join: this.org_year_join,
                org_year_end: this.org_year_end,
                org_business_name: this.org_business_name,
                org_business_name_file: this.org_business_name_file,
                org_business_position: this.org_business_position,
                org_status: this.org_status,
                org_file: this.org_file,
                job_type: this.job_type,
                property: this.property,
                property_file: this.property_file,
                property_other: this.property_other,
            };
            console.log("data:", data);
            const resp = await ax.post("/update-profile", data);
            console.log("resp update:", resp);
            this.isShowConfirmModal = true;
        }
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType" v-if="loaded">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-start">
                            <div class="my-3">
                                <b-form >
                                    <b-form-group class="mb-3" label="รหัสผู้ใช้ระบบ">
                                        <b-form-input v-model="vs_identity" type="text" placeholder="VSXXXXXX"
                                            :class="{ 'is-invalid': submittedRegisted && $v.vs_identity.$error }"
                                            :readonly="true"></b-form-input>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ชื่อ">
                                        <b-form-input v-model="firstname" type="text" placeholder="ชื่อจริง"
                                            :class="{ 'is-invalid': submittedCheckPrivilege && $v.firstname.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedCheckPrivilege && $v.firstname.$error" class="invalid-feedback">
                                            <div v-if="!$v.firstname.required">ต้องกรอกชื่อ</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="นามสกุล">
                                        <b-form-input v-model="lastname" type="text" placeholder="นามสกุลจริง"
                                            :class="{ 'is-invalid': submittedCheckPrivilege && $v.lastname.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedCheckPrivilege && $v.lastname.$error" class="invalid-feedback">
                                            <div v-if="!$v.lastname.required">ต้องกรอกนามสกุล</div>
                                        </div>
                                    </b-form-group>


                                    <b-form-group class="mb-3" label="วัน เดือน ปี เกิด">
                                        <b-form-input v-model="birthday" type="text"
                                            placeholder="วัน เดือน ปี เกิด เช่น 01/01/2500"
                                            :class="{ 'is-invalid': submittedRegisted && $v.birthday.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.birthday.$error" class="invalid-feedback">
                                            <span v-if="!$v.birthday.required">ต้องกรอกวัน เดือน ปี เกิด</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="Line ID">
                                        <b-form-input v-model="line_id" type="text" placeholder="Line ID"
                                            :class="{ 'is-invalid': submittedRegisted && $v.line_id.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.line_id.$error" class="invalid-feedback">
                                            <span v-if="!$v.line_id.required">ต้องกรอก Line ID</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="สัญชาติ">
                                        <b-form-input v-model="national" type="text" placeholder="สัญชาติ"
                                            :class="{ 'is-invalid': submittedRegisted && $v.national.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.national.$error" class="invalid-feedback">
                                            <span v-if="!$v.national.required">ต้องกรอกสัญชาติ</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เชื้อชาติ">
                                        <b-form-input v-model="origin" type="text" placeholder="เชื้อชาติ"
                                            :class="{ 'is-invalid': submittedRegisted && $v.origin.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.origin.$error" class="invalid-feedback">
                                            <span v-if="!$v.origin.required">ต้องกรอกเชื้อชาติ</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ศาสนา">
                                        <b-form-input v-model="religion" type="text" placeholder="ศาสนา"
                                            :class="{ 'is-invalid': submittedRegisted && $v.religion.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.religion.$error" class="invalid-feedback">
                                            <span v-if="!$v.religion.required">ต้องกรอกศาสนา</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เบอร์โทรศัพท์">
                                        <b-form-input v-model="phone" type="text" placeholder="เบอร์โทรศัพท์"
                                            :class="{ 'is-invalid': submittedRegisted && $v.phone.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.phone.$error" class="invalid-feedback">
                                            <span v-if="!$v.phone.required">ต้องกรอกเบอร์โทรศัพท์</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เลขประจำตัวประชาชน 13 หลัก">
                                        <b-input-group>
                                            <b-form-input v-model="identity_number" type="text"
                                                placeholder="เลขประจำตัวประชาขน 13 หลัก เช่น 1002345678991 เป็นต้น"
                                                :class="{ 'is-invalid': submittedCheckPrivilege && $v.identity_number.$error }"
                                                :readonly="completeAllField" maxlength="13"></b-form-input>
                                            <input class="sr-only" type="file" @change="previewFile"
                                                id="identity_number_file_input">
                                            <b-button variant="outline-success" type="button"
                                                @click="attachment('identity_number_file')"><i
                                                    class="uil uil-upload"></i></b-button>
                                        </b-input-group>

                                        <div v-if="submittedCheckPrivilege && $v.identity_number.$error"
                                            class="invalid-feedback">
                                            <div v-if="!$v.identity_number.required">ต้องกรอกหมายเลขบัตรประชาชน</div>
                                            <div v-if="!$v.identity_number.numeric">
                                                ต้องกรอกหมายเลขบัตรประชาชนเป็นตัวเลขเท่านั้น</div>
                                            <div v-if="!$v.identity_number.maxLength">ต้องกรอกหมายเลขบัตรประชาชน ไม่เกินกว่า
                                                13 หลัก</div>
                                            <div v-if="!$v.identity_number.minLength">ต้องกรอกหมายเลขบัตรประชาชน ไม่น้อยกว่า
                                                13 หลัก</div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="identity_number_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เลขที่ใบอนุญาต พท.ว.">
                                        <b-input-group>
                                            <b-form-input v-model="license1" type="text" placeholder="เลขที่ใบอนุญาต พท.ว."
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="license1_file_input">
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('license1_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="license1_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เลขที่ใบอนุญาต พท.ป.">
                                        <b-input-group>
                                            <b-form-input v-model="license2" type="text" placeholder="เลขที่ใบอนุญาต พท.ป."
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="license2_file_input">
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('license2_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="license2_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เลขที่ใบอนุญาต พท.ภ.">
                                        <b-input-group>
                                            <b-form-input v-model="license3" type="text" placeholder="เลขที่ใบอนุญาต พท.ภ."
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="license3_file_input">
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('license3_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="license3_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เลขที่ใบอนุญาต พท.น.">
                                        <b-input-group>
                                            <b-form-input v-model="license4" type="text" placeholder="เลขที่ใบอนุญาต พท.น."
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="license4_file_input" />
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('license4_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="license4_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เลขที่ใบอนุญาต พท.ผ.">
                                        <b-input-group>
                                            <b-form-input v-model="license5" type="text" placeholder="เลขที่ใบอนุญาต พท.ผ."
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="license5_file_input" />
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('license5_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="license5_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="บ้านเลขที่">
                                        <b-input-group>
                                            <b-form-input v-model="address_no" type="text" placeholder="บ้านเลขที่"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="หมู่บ้าน/ชุมชน">
                                        <b-input-group>
                                            <b-form-input v-model="address_mooban" type="text" placeholder="บ้านเลขที่"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ซอย">
                                        <b-input-group>
                                            <b-form-input v-model="address_soi" type="text" placeholder="ซอย"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ถนน">
                                        <b-input-group>
                                            <b-form-input v-model="address_road" type="text" placeholder="ถนน"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ตำบล">
                                        <b-input-group>
                                            <b-form-input v-model="address_tambon" type="text" placeholder="ตำบล"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="อำเภอ">
                                        <b-input-group>
                                            <b-form-input v-model="address_aumphur" type="text" placeholder="อำเภอ"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="จังหวัด">
                                        <b-input-group>
                                            <b-form-input v-model="address_province" type="text" placeholder="จังหวัด"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="รหัสไปรษณีย์">
                                        <b-input-group>
                                            <b-form-input v-model="address_postcode" type="text" placeholder="รหัสไปรษณีย์"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <h4>สังกัด</h4>

                                    <b-form-group class="mb-3" label="ชื่อองค์การปกครองส่วนท้องถิ่นที่อยู่อาศัย">
                                        <b-input-group>
                                            <b-form-input v-model="org_name" type="text"
                                                placeholder="ชื่อองค์การปกครองส่วนท้องถิ่นที่อยู่อาศัย"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ชื่อสถาบันที่ทำการศึกษาด้านวิชาชีพการแพทย์แผนไทย">
                                        <b-input-group>
                                            <b-form-input v-model="org_institute" type="text"
                                                placeholder="ชื่อสถาบันที่ทำการศึกษาด้านวิชาชีพการแพทย์แผนไทย"
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="org_institute_file_input" />
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('org_institute_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="org_institute_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ปีที่ทำเข้าทำการศึกษา">
                                        <b-input-group>
                                            <b-form-input v-model="org_year_join" type="text"
                                                placeholder="ปีที่ทำเข้าทำการศึกษา"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ปีที่จบการศึกษา">
                                        <b-input-group>
                                            <b-form-input v-model="org_year_end" type="text" placeholder="ปีที่จบการศึกษา"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ชื่อหน่วยงานที่ประกอบกิจการ">
                                        <b-input-group>
                                            <b-form-input v-model="org_business_name" type="text"
                                                placeholder="ชื่อหน่วยงานที่ประกอบกิจการ"
                                                :readonly="completeAllField"></b-form-input>
                                            <b-input-group-append>
                                                <input class="sr-only" type="file" @change="previewFile"
                                                    id="org_business_name_file_input" />
                                                <b-button variant="outline-success" type="button"
                                                    @click="attachment('org_business_name_file')"><i
                                                        class="uil uil-upload"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="org_business_name_file" />
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ตำแหน่งงานที่ประกอบกิจการ">
                                        <b-input-group>
                                            <b-form-input v-model="org_business_position" type="text"
                                                placeholder="ตำแหน่งงานที่ประกอบกิจการ"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                    <hr class="my-3" />

                                    <b-input-group>
                                        <b-form-group label="สถานะผู้แทนหน่วยงาน" v-slot="">
                                            <b-form-radio v-model="org_status" value="มีอำนาจดำเนินการแทนหน่วยงาน"><span
                                                    class="ms-1"></span>มีอำนาจดำเนินการแทนหน่วยงาน</b-form-radio>
                                            <b-form-radio v-model="org_status" value="ไม่มีอำนาจดำเนินการแทนหน่วยงาน"><span
                                                    class="ms-1"></span>ไม่มีอำนาจดำเนินการแทนหน่วยงาน</b-form-radio>
                                        </b-form-group>
                                        <b-input-group-append>
                                            <input class="sr-only" type="file" @change="previewFile" id="org_file_input" />
                                            <b-button variant="outline-success" type="button"
                                                @click="attachment('org_file')"><i class="uil uil-upload"></i></b-button>
                                        </b-input-group-append>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="org_file" />
                                            </div>
                                        </div>
                                    </b-input-group>

                                    <hr class="my-3" />

                                    <b-form-group label="ประเภทการทำงาน" v-slot="">
                                        <b-form-radio v-model="job_type" value="เกี่ยวกับวิชาชีพการแพทย์แผนไทย"><span
                                                class="ms-1"></span>เกี่ยวกับวิชาชีพการแพทย์แผนไทย</b-form-radio>
                                        <b-form-radio v-model="job_type" value="ไม่เกี่ยวกับวิชาชีพการแพทย์แผนไทย"><span
                                                class="ms-1"></span>ไม่เกี่ยวกับวิชาชีพการแพทย์แผนไทย</b-form-radio>
                                        <b-form-radio v-model="job_type" value="อื่นๆ"><span
                                                class="ms-1"></span>อื่นๆ</b-form-radio>
                                    </b-form-group>

                                    <hr class="my-3" />

                                    <b-input-group>
                                        <b-form-group label="คุณสมบัติด้านวิชาชีพการแพทย์แผนไทยเพิ่มเติม" v-slot="">
                                            <b-form-radio v-model="property" value="property_doctor_file"><span
                                                    class="ms-1"></span>ครูแพทย์แผนไทย</b-form-radio>
                                            <b-form-radio v-model="property" value="property_ast_doctor_file"><span
                                                    class="ms-1"></span>ผู้ช่วยแพทย์แผนไทย</b-form-radio>
                                            <b-form-radio v-model="property" value="property_seminar_255_file"><span
                                                    class="ms-1"></span>ผ่านการอบรมในหลักสูตรผู้พิการทางการนวด 255
                                                ชั่วโมง</b-form-radio>
                                            <b-form-radio v-model="property" value="property_seminar_150_file"><span
                                                    class="ms-1"></span>ผ่านการอบรมในหลักสูตรนวดไทย 150
                                                ชั่วโมง</b-form-radio>
                                            <b-form-radio v-model="property" value="property_seminar_60_file"><span
                                                    class="ms-1"></span>ผ่านการอบรมในหลักสูตรนวดไทย 60
                                                ชั่วโมง</b-form-radio>
                                        </b-form-group>
                                        <b-input-group-append>
                                            <input class="sr-only" type="file" @change="previewFile"
                                                id="property_file_input" />
                                            <b-button variant="outline-success" type="button"
                                                @click="attachment('property_file')"><i
                                                    class="uil uil-upload"></i></b-button>
                                        </b-input-group-append>
                                        <div class="row mt-2">
                                            <div class="col-12 col-xl-3 col-xxl-3 col-lg-4 col-md-6">
                                                <img class="img-fluid" :src="property_file" />
                                            </div>
                                        </div>
                                    </b-input-group>

                                    <hr class="my-3" />

                                    <b-form-group class="mb-3" label="ความสามารถด้านกรรมวิธีการแพทย์แผนไทย">
                                        <b-input-group>
                                            <b-form-input v-model="property_other" type="text"
                                                placeholder="#ความสามารถด้านกรรมวิธีการแพทย์แผนไทย"
                                                :readonly="completeAllField"></b-form-input>

                                        </b-input-group>
                                    </b-form-group>

                                </b-form>
                            </div>

                        <div class="my-3">
                            <b-form @submit.prevent="saveData()">
                                <div class="mt-3 text-end">
                                    <b-button type="submit" variant="success" class="w-sm" >บันทึกข้อมูล</b-button>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Horizontal>
    <successModal :isShow="isShowConfirmModal" v-on:close="closeConfirmModal"></successModal>
</div></template>
