<script>
import ProfileLayout from "../../layouts/profile.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "ระบบหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    ProfileLayout,
    PageHeader,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "Thaimed",
        },
        {
          text: "ข้อมูลส่วนตัว",
          active: true,
        },
      ],
    };
  },
  computed: {},
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<template>
  <div>
    <ProfileLayout>
      <PageHeader :title="title" :items="items" />
    </ProfileLayout>
  </div>
</template>